import React from 'react';
import { LandingPage } from './landing/Landing';

export const App = () => {
  return (
    <>
      <LandingPage />
    </>
  );
}
